import React, {useEffect, useRef, useState, useContext } from "react";
import videojs from "@mux/videojs-kit";

import { SocketContext} from '../../../context/socket';

import "@mux/videojs-kit/dist/index.css";
import './playerStyles.css';

import LoadingSpinnerCenter from '../../UIElements/Loading/LoadingSpinnerCenter';

const VideoJS = ( props ) => {

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady, streamData, userId } = props;
  const [isBuffering, setIsBuffering] = useState(false);
  const [isLive, setIsLive] = useState(false);
 
  const { REACT_APP_MUX_DATA_KEY } = process.env
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.on('STREAM_STATUS', dataFromServer => {
      dataHandler(dataFromServer);
    });
    return () => {
      socket.off('STREAM_STATUS');
    };
  }, []);

  const dataHandler = data => {
    if (data.id === streamData.muxStreamId) {
      if (data.action === 'video.live_stream.active') {
        setIsLive(true);
        console.log('LIVE');
      }

      if (data.action === 'video.live_stream.idle' || data.action === 'video.live_stream.disabled' ) {
        setIsLive(false);
        console.log('NOT LIVE');
      }
    }
  };

  const buffering = () => {
    setIsBuffering(true);
  }

  const stopBuff = () => {
    setIsBuffering(false);
  }

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      });
    } else {

      console.log('already a player ');
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.poster(options.poster);
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.type('video/mux')
      player.controls(true);
      console.log(player);
    }
  }, [options, videoRef, streamData, isLive]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const errorHandler = () => {
    stopBuff();
    setIsLive(false);
  }
  
 const muxData = `{
  "timelineHoverPreviews": true,
  "redundant_streams": true,
  "plugins": {
    "mux": {
      "debug": true,
      "data":{
        "env_key":"${REACT_APP_MUX_DATA_KEY}",
        "video_title": "${streamData.title}",
        "video_id": "${streamData.id}",
        "viewer_user_id": "${userId}",
        "video_language_code": "${streamData.langIso}",
        "video_content_type":"event",
        "video_stream_type":"live"
      }
    }
  }
}`

  return (
    <div data-vjs-player>
     {isBuffering && <LoadingSpinnerCenter message='buffering...' />}
      <video 
        ref={videoRef} 
        playsInline={true}
        className="video-js vjs-big-play-centered"  
        data-setup={muxData}
        onWaiting={buffering}
        onPlaying={stopBuff}
        onCanPlay={stopBuff}
        onError={errorHandler}
        onPause={stopBuff}

      />
    </div>
  );
}

export default VideoJS;