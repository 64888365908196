import React, { useContext, useState, useEffect } from 'react';
import VideoJS from './VideoJS';

import { AuthContext } from '../../../context/auth-context';
import Placeholder from '../Placeholder';

const Player = ({ streamData }) => {

  const { REACT_APP_API_URL } = process.env;
  const { userId } = useContext(AuthContext);
  const [videoJsOptions, setVideoJsOptions] = useState();

  const playerRef = React.useRef(null);
//${streamData.playbackId}
  useEffect(() => {
    console.log(streamData);
    streamData &&
      setVideoJsOptions(() => ({
        autoplay: true,
        controls: true,
        liveui: true,
        preload: 'auto',
        muted: true,
        fluid: true,
        poster: `${REACT_APP_API_URL}/${streamData.posterUri}`,
        sources: [
          {
            src: streamData.playbackId,
            type: 'video/mux',
          },
        ],
      }));
  }, [streamData]);

  const handlePlayerReady = player => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('playing', () => {
      console.log('player is playing');
    });

    player.on('pause', () => {
      console.log('player is paused');
    });

    player.on('canPlay', () => {
      console.log('player Can Play');
    });

    player.on('progress', () => {
      console.log('player progress');
    });

    player.on('error', () => {
      console.log('player error');
      console.log(player.current);
    });

    player.on('currentTime', () => {
      console.log('player time');
      console.log(player.current.currentTime);
    });
  };

  // const changePlayerOptions = () => {
  //   // you can update the player through the Video.js player instance
  //   if (!playerRef.current) {
  //     return;
  //   }
  //   // [update player through instance's api]
  //   playerRef.current.src([{src: 'http://ex.com/video.mp4', type: 'video/mp4'}]);
  //   playerRef.current.autoplay(false);
  // };

  return (
    <>
      {videoJsOptions && (
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          streamData={streamData}
          userId={userId}
        />
      )}
      {!videoJsOptions && (
        <Placeholder/>
      )}
    </>
  );
};

export default Player;
