import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/UIElements/Loading/LoadingSpinnerCenter';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import styles from './LiveStream.module.css';

import Player from '../Video/testing/Player';

const STREAMDATA_TEST = 
  {
    id: 'wwwwwwqwdfr',
    title: 'Breakout Room 1 - Climate Changing Coffee',
    langISO: 'en',
    playbackId: 'KcGsNxdP00uo3gcCMpbJvUN02vGAkaKqu6YMnKa01dvLOI',
    posterUri:
      'uploads/content/posters/coffee-demo-breakout-poster-en.jpg',
  };

const LiveStream = ({ eventId }) => {

  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [currentLang, setCurrentLang] = useState('');
  const [liveStream, setLiveStream] = useState();
  const [liveStreams, setLiveStreams] = useState();
  const [showMain, setShowMain] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (liveStreams && t('code') !== currentLang) {
      console.log(t('code'));
      const stream = liveStreams.find(s => s.langIso === t('code'));
      console.log(stream);
      setLiveStream(stream);
      setCurrentLang(t('code'));
    }
  }, [t]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      console.log('get stream');
      const fetchStreams = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/live/event-streams-list`,
            'POST',
            JSON.stringify({
              eventId: eventId,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );

          if (response.streams) {
            console.log(response.streams);
            setLiveStreams(response.streams);
            console.log(t('code'));
            const stream = response.streams.find(s => s.langIso === t('code'));
            console.log(stream);
            setLiveStream(stream);
          }
        } catch (err) {
          //console.log(err);
        }
      };
      fetchStreams();
    } else {
      //console.log("Not authorised");
      navigate('/auth');
    }
  }, []);

  return (
    <div id={styles.liveStream}>
      <ErrorModal error={error} onClear={clearError} />
      <div id={styles.video}>
        <div className={styles.videoContainer}>
          {isLoading && <LoadingSpinner />}

          <AnimatePresence initial={false} exitBeforeEnter>
            {!isLoading && showMain && liveStream && (
              <motion.div
                key="Main Stream"
                className={styles.streamWrapper}
                initial={{
                  opacity: 1,
                  x: '-100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '80deg',
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  perspective: '0px',
                  rotateX: '0deg',
                  rotateY: '0deg',
                }}
                exit={{
                  opacity: 1,
                  x: '100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '-80deg',
                }}
                transition={{ duration: 0.6 }}
              >
                <Player streamData={liveStream} />
              </motion.div>
            )}

            {!showMain && (
              <motion.div
                key="Breakout Room"
                className={styles.streamWrapper}
                initial={{
                  opacity: 1,
                  x: '-100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '80deg',
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  perspective: '0px',
                  rotateX: '0deg',
                  rotateY: '0deg',
                }}
                exit={{
                  opacity: 1,
                  x: '100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '-80deg',
                }}
                transition={{ duration: 0.6 }}
              >
                <Player streamData={STREAMDATA_TEST} />
              </motion.div>
            )}

            {/* {!isLoading && showMain && !liveStream && (
              <motion.div
                key="No Main Stream"
                className={styles.streamWrapper}
                initial={{
                  opacity: 1,
                  x: '-100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '80deg',
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  perspective: '0px',
                  rotateX: '0deg',
                  rotateY: '0deg',
                }}
                exit={{
                  opacity: 1,
                  x: '100vw',
                  scale: 0.4,
                  perspective: '600px',
                  rotateX: '-25deg',
                  rotateY: '-80deg',
                }}
                transition={{ duration: 0.6 }}
              >
               <Placeholder/>
              </motion.div>
              )} */}

          </AnimatePresence>
        {/* <button
          style={{position: 'absolute',
          top: '0',
          right: '0'}}
            type="button"
            onClick={() => setShowMain(showMain ? false : true)}
          >
            move
          </button>   */}
        </div>
      </div>
    </div>
  );
};

export default LiveStream;
