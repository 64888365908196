import React, { useState, useEffect, useContext, Fragment } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../shared/context/socket';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EventContext } from '../../shared/context/event-context';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import LiveStream from '../../shared/components/Live/LiveStream';
import QandA from '../../shared/components/QandA/QandA';
import Polls from '../../shared/components/Polls/Polls';
import Agenda from '../../shared/components/Agenda/Agenda';
import NewsMarquee from '../../shared/components/NewsMarquee/NewsMarquee';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from './Event.module.css';

const Event = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
    in: {
      opacity: 1,
      duration: 2,
      x: 0,
    },
    out: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
  };

  console.log('Event render');

  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const eventCtx = useContext(EventContext);
  const socket = useContext(SocketContext);
  const { email, userId, lang, isLoggedIn, token, logout } = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [event, setEvent] = useState();
  const [eventData, setEventData] = useState(JSON.parse(localStorage.getItem("eventStatusData"))); 
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [AccountSuspended, setAccountSuspended] = useState();

  const changeLanguage = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
  }

  const setLang = (language) => {
    dispatch({ type: 'updateLanguage', lang: language})
  }

  useEffect(() => {

    const eventStatusData = JSON.parse(localStorage.getItem("eventStatusData"));
    setEventData(eventStatusData);
    console.log(eventData);
   
    if (isLoggedIn) {
  
      const fetchEvent = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/event`,
            'POST',
            JSON.stringify({
              eid: eventStatusData.id
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
      
          if (response.event) {
            setEvent(response.event);
            eventCtx.createEvent(response.event.id, response.event.title, response.event.subTitle, response.event.uri, response.event.date, response.event.open, response.event.supportEmail);
          }
        } catch (err) {
          console.log(err);
        }
      };
      eventStatusData && fetchEvent();
    } else {
      //console.log('Not authorised');
      navigate(`/`);
    }
  }, [token]);

  useEffect(() => {
    const localLang = localStorage.getItem('i18nextLng')
    const newLang = lang ? lang : localLang;
    //console.log(newLang );
    changeLanguage(newLang);
  }, [lang])

   // check connection status
   useEffect(() => {
    
    if (!socket.id && eventData && email) {
      
      socket.auth = { 
        userId,
        email,
        evid: eventData.id
      };
      console.log('socket');
      console.log(socket.auth);
      socket.connect();
    }
   
}, [socket.id, eventData, email]);

  // listen for new messages on WS
  useEffect(() => {
    socket.on('AUTH', dataFromServer => {
      console.log(dataFromServer);
      wsAuthHandler(dataFromServer);
    });
    return () => {
      socket.off('AUTH');
    };
  }, [socket]);


  const wsAuthHandler = data => {
    if (data) {
      if (data.userId) {
        switch (data.action) {
          case 'Ticket Invalid':
            invalidTicketHandler(data.userId);
            break;

          case 'Account Suspended':
            setAccountSuspended(data.userId);
            break;

            case 'Exit User':
              exitEventHanfdler(data.userId);
              break;

          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    accountsuspendHandler(AccountSuspended);
  }, [AccountSuspended])

  const invalidTicketHandler = id => {
    if (id === userId) {
      socket.emit('logout', { socketId: socket.id });
      logout();
    }
  };

  const accountsuspendHandler = id => {
    console.log(id);
    console.log(userId);
    if (id === userId) {
      socket.emit('logout', { socketId: socket.id });
      logout();
    }
  };

  const exitEventHanfdler = id => {
    if (id === userId) {
      socket.emit('logout', { socketId: socket.id });
      logout();
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <motion.div
        className={styles.pageWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        
        
        {!isLoading && event && <LiveStream eventId={event.id} />}
        {!isLoading && event && <NewsMarquee eventId={event.id} />}
        {isLoading && <LoadingSpinner />}
      </motion.div>
      {!isLoading && event &&<QandA eventId={event.id}/>}
      {event && <Polls eventId={event.id}/>}
      {event &&<Agenda  eventId={event.id} />}
    </Fragment>
  );
};

export default Event;
