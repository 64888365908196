import React from 'react'
import logoSvg from '../../../img/logo.svg';
import styles from './Logo.module.css';

const Logo = () => {
    return (
        <div id={styles.logo}>
            <img src={logoSvg} />
        </div>
    )
}

export default Logo
