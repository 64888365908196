import React, { Fragment, useState, useEffect, useContext } from 'react';
import { faComments, faList, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';
import { SocketContext } from '../../context/socket';
import { useTranslation } from 'react-i18next';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import LoadingSpinner from '../UIElements/Loading/LoadingSpinnerCenter';
import QASubmitForm from './QASubmitForm';
import QABody from './QABody';
import styles from './QandA.module.css';
import NoSessionsNotice from './NoSessionsNotice';
import QASessionList from './QASessionList';

const variants = {
  open: { opacity: 1, right: 0 },
  closed: { width: '0', opacity: 0, right: -500 },
};

const QandA = ({ eventId }) => {
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState();
  const [sessionTitle, setSessionTitle] = useState('Select a Q&A Session');
  const [updateQuestion, setUpdateQuestion] = useState();
  const [toggledSession, setToggledSession] = useState();
  const [liveSessions, setLiveSessions] = useState([]);

  const [sessionState, setSessionState] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState();
  const [approvedQuestion, setApprovedQuestion] = useState();
  const [rewindData, setRewindData] = useState();

  const updateQuestions = data => {
    const sid = getCurentSessionId();
    if (data && sid) {
      if (data.question) {
        if (data.question.sessionId === sid) {
          switch (data.action) {
            case 'new':
              setNewQuestion(data.question);
              break;

            case 'approved':
              setApprovedQuestion(data.question);
              break;

            case 'new answer':
              setUpdateQuestion(data.question);
              break;

            case 'selected':
              setUpdateQuestion(data.question);
              break;

            case 'answered':
              setUpdateQuestion(data.question);
              break;

            case 'rewind':
              setRewindData(data);
              break;

            default:
              break;
          }
        }
      }
      if (data.action === 'session toggle') {
        console.log('session toggle');
        setToggledSession(data);
      }
    }
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/qa/qa-sessions/${eventId}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        if (responseData.sessions) {
          setLiveSessions(getLiveSessions(responseData.sessions));
          console.log(responseData.sessions);
          setSessions(() => [...responseData.sessions]);
          if (responseData.sessions.length === 1) {
            selectedSessionHandler(responseData.sessions[0]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchSessions();
  }, []);

  // listen for new questions on WS
  useEffect(() => {
    socket.on('Q&A', dataFromServer => {
      updateQuestions(dataFromServer);
      console.log(dataFromServer.action);
    });

    return () => {
      socket.off('Q&A');
    };
  }, [socket]);

  const selectedSessionHandler = async session => {
    setSessionTitle(session.name);

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/qa/qa-session/${session.id}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      if (responseData.session) {
        setSelectedSession(responseData.session);
        setSessionState(responseData.session.live);
        localStorage.setItem(
          'sessionData',
          JSON.stringify({ sid: responseData.session.id })
        );
        setQuestions(() => [...responseData.session.qaQuestions]);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const resetView = () => {
    setSelectedSession(null);
    setSessionTitle('Select a Q&A Session');
    setQuestions([]);
  };

  useEffect(() => {
    updateQuestion && addAnswer();
  }, [updateQuestion]);

  useEffect(() => {
    newQuestion && addQuestion(newQuestion);
  }, [newQuestion]);

  useEffect(() => {
    approvedQuestion && approveQuestion(approvedQuestion);
  }, [approvedQuestion]);

  useEffect(() => {
    rewindData && rewindQuestion(rewindData);
  }, [rewindData]);

  useEffect(() => {
    checkLiveSessions(sessions);
  }, [sessions]);

  useEffect(() => {
    toggleSessionState(toggledSession);
  }, [toggledSession]);

  const addQuestion = newQuestionData => {
    const duplicate = questions.find(q => q.id === newQuestionData.id);
    //console.log(duplicate);

    if (!duplicate) {
      if (newQuestionData.authorId === auth.userId) {
        setQuestions(currentQs => [newQuestionData, ...currentQs]);
      }
    }
  };

  const approveQuestion = question => {
    if (question.authorId !== auth.userId) {
      const duplicate = questions.find(q => q.id === question.id);
      if (!duplicate) {
        setQuestions(currentQs => [question, ...currentQs]);
      }
    }
  };

  const addAnswer = () => {
    let updatedQuestions = questions;
    let question = updatedQuestions.find(q => {
      return q.id === updateQuestion.id;
    });

    if (question) {
      updateQuestion.approved && (question.approved = updateQuestion.approved);
      updateQuestion.answered && (question.answered = updateQuestion.answered);
      updateQuestion.answeredAt &&
        (question.answeredAt = updateQuestion.answeredAt);
      updateQuestion.selected && (question.selected = updateQuestion.selected);
      updateQuestion.selectedAt &&
        (question.selectedAt = updateQuestion.selectedAt);
      updateQuestion.qaAnswers &&
        (question.qaAnswers = updateQuestion.qaAnswers);
    }
    setQuestions([...updatedQuestions]);
  };

  const toggleSessionState = data => {
    if (data) {
      if (data.sessionId) {
        const sid = getCurentSessionId();
        if (data.sessionId === sid) {
          resetView();
        }

        const idx = sessions.findIndex(
          session => session.id === data.sessionId
        );
        console.log(idx);
        if (idx >= 0) {
          console.log('match found');
          const updatedSessions = sessions;
          updatedSessions[idx].live = data.isLive;
          console.log(updatedSessions);
          setLiveSessions(getLiveSessions(updatedSessions));
          checkLiveSessions(updatedSessions);
        }
      }
    }
  };

  const getCurentSessionId = () => {
    const data = localStorage.getItem('sessionData');

    if (data) {
      return JSON.parse(data).sid;
    }
  };

  const rewindQuestion = data => {
    const index = questions.findIndex(q => q.id === data.question.id);
    const newQs = [...questions];
    newQs[index] = data.question;
    setQuestions(newQs);
  };

  const checkLiveSessions = sessionData => {
    if (sessionData) {
      let live = false;
      if (sessionData.length > 0) {
        live = sessionData.some(session => session.live === true);
      }
     
      console.log(live ? 'isLive' : 'No Live sessions');
    }
  };

  const getLiveSessions = sessionData => {
    let result = [];
    if (sessionData) {
      if (sessionData.length > 0) {
        sessionData.map(session => {
          session.live && result.push(session);
        });
      }
    }
    console.log(result.length);
    result.length === 0 && setIsOpen(false);
    result.length === 1 && selectedSessionHandler(result[0]);
    return result;
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {liveSessions.length > 0 && (
        <div id={styles.qaWrapper} onClick={() => setIsOpen(isOpen => !isOpen)}>
          <FontAwesomeIcon icon={faComments} />
          <span>Q&A</span>
        </div>
      )}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="drawer"
          className={styles.qaDrawWrapper}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>{sessionTitle}</h1>
          </div>

          <div className={styles.qaBodyWrapper}>
            {!liveSessions && (
              <NoSessionsNotice
                title={t('closed')}
                noticeText={'All sessions are currently closed.'}
              />
            )}
            {questions && selectedSession && !isLoading && (
              <QABody
                questionList={questions}
                userId={auth.userId}
                sessionState={sessionState}
              />
            )}
            {isLoading && (
              <div className={styles.centerSpinner}>
                <div className={`center ${styles.loadingSpinner}`}>
                  <LoadingSpinner />
                  <p className={styles.loadingText}>{t('please wait')}...</p>
                </div>
              </div>
            )}
            {liveSessions.length > 1 && !selectedSession && !isLoading && (
              <QASessionList
                sessions={liveSessions}
                selectedSession={selectedSessionHandler}
              />
            )}
          </div>

          <div className={styles.editorWrapper}>
            {selectedSession && (
              <div className={styles.askQuestionForm}>
                <QASubmitForm
                  sessionId={selectedSession.id}
                  isOpen={sessionState}
                />
              </div>
            )}
            <div className={styles.closeWrapper}>
              <div
                className={styles.closeBtn}
                onClick={() => setIsOpen(isOpen => !isOpen)}
              >
                <span className={styles.closeIcon}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <p className={styles.closeText}>{t('close')}</p>
              </div>

              {liveSessions.length > 1 && (
                <div className={styles.sessionBtn} onClick={resetView}>
                  <span className={styles.sessionIcon}>
                    <FontAwesomeIcon icon={faList} />
                  </span>
                  <p className={styles.sessionText}>{t('sessions')}</p>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </Fragment>
  );
};

export default QandA;
