import React, { useEffect, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LangContext } from '../../context/lang-context';

import Cookies from 'js-cookie';

import styles from './LangSelectSm.module.css';
import RegionList from './RegionList';
import Flag from '../UIElements/Flag';

const variants = {
  open: { opacity: 1, right: 0 },
  closed: { width: '0', opacity: 0, right: -500 },
};

const LangSelectSm = ({ menuClassName, updateRegions }) => {
  const [regions, setRegions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { createLang} = useContext(LangContext)

  // const langCookie = Cookies.get('i18next');
  // if (langCookie !== 'en') {
  //   createLang(langCookie);
  // }

  const { t, i18n } = useTranslation();

  const changeLanguage = language => {
    setIsOpen(false);
    setLang(language);
    i18n.changeLanguage(language);
  };

  const setLang = language => {
    //dispatch({ type: 'updateLanguage', lang: language });
    createLang(language);
  };

  useEffect(() => {
    const regionData = JSON.parse(localStorage.getItem('eventStatusData'))
    if (regionData) {
      if (regionData.regions) {
        setRegions(regionData.regions);
      }
    }
  
  }, [updateRegions]);

  return (
    <div id={styles.langSelSm}>
      {regions && regions.length > 1 && (
        <div
          className={`${styles.regionSel} ${menuClassName}`}
          onClick={() => setIsOpen(true)}
        >
          <Flag code={t('code')} />{' '}
          <span className={styles.langName}>{t('code')}</span>
          <FontAwesomeIcon icon={ faChevronDown}/>
        </div>
      )}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="lang_drawer"
          className={styles.langDrawWrapper}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className={styles.overlay}></div>
          <button
            className={styles.closeBtn}
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Close X
          </button>
          <div className={styles.content}>
            {isOpen && (
              <RegionList regions={regions} SelectedLang={changeLanguage} />
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default LangSelectSm;
