import React, {useContext} from 'react'; 

import { SocketContext } from '../../context/socket';
import { AuthContext} from '../../context/auth-context';
import { EventContext } from '../../context/event-context';

import './TopLinks.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LangSelectSm from '../Language/LangSelectSm';


const TopLinks = () => {

    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const eventCtx = useContext(EventContext)

    const logout = () => {
        socket.disconnect()
        eventCtx.clearEvent()
        auth.logout()
    }

    return (
        <React.Fragment>
        <LangSelectSm />

            {auth.isLoggedIn && (
                <ul className="top-links">
                
                    <li>
                   
                        <button className="top-links top-links__logout" onClick={logout}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        </button>
                    </li>
                    
                </ul>
            )}
    
    </React.Fragment>
    );
};

export default TopLinks;