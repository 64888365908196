import React, { Fragment, useState, useEffect, useContext } from 'react';
import { faListAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocketContext } from '../../context/socket';
import { motion, AnimatePresence } from 'framer-motion';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';

import ErrorModal from '../UIElements/Modal/ErrorModal';
import LoadingSpinner from '../UIElements/LoadingSpinner';

import styles from './Agenda.module.css';
import AgendaList from './AgendaList';

const variants = {
    open: { opacity: 1, right: 0 },
    closed: { width:'0', opacity: 0, right: -500 },
  };

const Agenda = ({ eventId, showClose, closed }) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
  
    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [agenda, setAgenda] = useState([]);

    useEffect(() => {
        const fetchAgenda = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/agenda/all/${eventId}`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(responseData);
            if (responseData.agenda) {
                setAgenda(responseData.agenda);
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchAgenda();
      }, []);

    return (
        <Fragment>
        <ErrorModal error={error} onClear={clearError} />
        <div id={styles.agendaWrapper} onClick={() => setIsOpen(isOpen => !isOpen)}>
          <FontAwesomeIcon icon={faListAlt} />
          <span>{t('agenda')}</span>
        </div>
  
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key="agendaDrawer"
            className={styles.agendaDrawWrapper}
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
          >
            <div className={styles.titleWrapper}>
              <h1 className={styles.title}>{t('agenda')}</h1>
            </div>
  
            <div className={styles.agendaBodyWrapper}>
  
        
              {isLoading && (
                <div className={styles.centerSpinner}>
                  <div className={`center ${styles.loadingSpinner}`}>
                    <LoadingSpinner />
                    <p className={styles.loadingText}>{t('please wait')}...</p>
                  </div>
                </div>
              )}

              {agenda && !isLoading &&  <AgendaList agenda={agenda}/> }
             
            </div>
  
            <div className={styles.editorWrapper}>
          
              <div className={styles.closeWrapper}>
                  <div className={styles.closeBtn} onClick={() => setIsOpen(isOpen => !isOpen)}>
                    <span className={styles.closeIcon}><FontAwesomeIcon icon={faTimes} /></span>
                    <p  className={styles.closeText}>{t('close')}</p>
                  </div>
  
                 
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </Fragment>
    )
}

export default Agenda
