import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerCenter';

import './index.css';
import App from './App';

import store from './store/index';
import './utilities/i18n';

ReactDOM.render(
  <Provider store={store}>
  <Suspense fallback={<div className='center'><LoadingSpinner /></div>}>
   
      <App />
   
  </Suspense>
</Provider>
,
document.getElementById('root')
);


