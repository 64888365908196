import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Placeholder.module.css';

const Placeholder = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <h1 className={styles.title}>No Active Streams!</h1>
        <p className={styles.text}>
          You need to either configure or activte a live stream or an on demand asset.
        </p>
      </div>
    </div>
  );
};

export default Placeholder;
